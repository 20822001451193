import React, { useContext, useState, useEffect, FC } from "react"
import { FaSuitcase } from "react-icons/fa"
import Image from "next/image"
import s from "./Card.module.scss"
import colorMap from "../Helpers/ColorMap"
import { motion, useAnimation } from "framer-motion"
import { itemNames } from "../../../utils/enum/constants"
import { GalleryCardHape, Hape } from "../../types"
import { fetchGenerateDavenator } from "../../../services/fashion-index/inventoryServices"
import { processDynamicTraitsForDavenator } from "../../../utils/dynamic-equipment/helpers"
import { useGetDavenatorImageForCard } from "../../../services/fashion-index/GalleryServices"
import { HapeDetails } from "../../types/fashionIndex/InventoryTypes"

interface CardProps {
	card: GalleryCardHape
	rank: number
	onReadMore: (card: HapeDetails) => void
	cardSize: number
	isViewingMyHapes: boolean
	isMobile: boolean
}
const Card: FC<CardProps> = ({
	card,
	rank,
	onReadMore,
	cardSize,
	isViewingMyHapes,
	isMobile,
}) => {
	const { token_id, equipped_traits } = card

	const myHapesDimensions = 1000
	const [loading, setLoading] = useState(true)

	// get individual image for card. e.g. https://d2txl75rmr4hou.cloudfront.net/fit-in/450x450/217.png
	const [imageSrc, setImageSrc] = useState(
		`https://d2txl75rmr4hou.cloudfront.net/fit-in/${
			isViewingMyHapes && !isMobile
				? `${myHapesDimensions}x${myHapesDimensions}`
				: "450x450"
		}/${token_id}.png`,
	)

	const getDimensions = () => {
		return isViewingMyHapes ? myHapesDimensions : 450
	}

	const [wornBadgeSrc, setWornBadgeSrc] = useState(null)
	const [isWornBadgeLoaded, setIsWornBadgeLoaded] = useState(false)

	const [badgeSrc, setBadgeSrc] = useState(null)

	const getBackgroundColor = () => {
		if (equipped_traits) {
			const background = equipped_traits.find((f) => f.category === "Fur").value
			return colorMap[background]
		} else {
			return ""
		}
	}

	const item = {
		hidden: { opacity: 0 },
		show: { opacity: 1 },
	}

	// Request image from davenator
	const { data: davenatorImage, isSuccess: isDavenatorImageLoaded } =
		useGetDavenatorImageForCard(card.token_id, card.is_reset_to_native)

	const [isTokenIdVisible, setIsTokenIdVisible] = useState(false)
	return (
		<div className={`${s["bad-col-3"]} ${s.zoom} d-flex`} data-item="card">
			<motion.div variants={item}>
				<div
					// className={`${
					// 	isFilterSidebarOpen
					// 		? s.card_with_filter_open
					// 		: s.card_with_filter_closed
					// } ${isEventHape ? s.gradient_background : null}`}
					className={`${s.card_transition} group`}
					style={{ width: cardSize, height: cardSize }}
					onClick={() => {
						onReadMore(card as any)
					}}
					onMouseEnter={() => setIsTokenIdVisible(true)}
					onMouseLeave={() => setIsTokenIdVisible(false)}
				>
					<div className={`${s.card}`}>
						<div
							className={`${s["card__face"]} ${s["card__face--front"]}`}
							// style={{ backgroundColor: getBackgroundColor() }}
						>
							<div>
								<span className={`${s.rank} z-5 absolute start-0 top-0 p-2`}>
									{rank}
								</span>
								<span
									style={{
										opacity: isTokenIdVisible ? 1 : 0,
										transition: "opacity 100ms ease-in-out",
									}}
									className={`${s.rank} z-5 absolute bottom-0 end-0 p-2`}
								>
									#{card.token_id}
								</span>
								<div className="relative">
									<div
										className={`absolute inset-0 transition-opacity duration-500 ease-in-out`}
									>
										<Image
											className={`${s.avatar_image}`}
											placeholder={`blur`}
											src={imageSrc}
											blurDataURL={imageSrc}
											priority={true}
											width={getDimensions()}
											height={getDimensions()}
											quality={100}
											alt={token_id.toString()}
											onLoadingComplete={() => setLoading(false)}
										/>
									</div>
									<div
										className={`absolute inset-0 transition-opacity duration-500 ease-in-out ${isDavenatorImageLoaded ? "opacity-100" : "opacity-0"}`}
									>
										{davenatorImage && (
											<Image
												src={davenatorImage}
												alt="Equipped Image"
												width={getDimensions()}
												height={getDimensions()}
												quality={100}
											/>
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</motion.div>
		</div>
	)
}

export default Card
