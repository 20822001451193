import React, {
	useEffect,
	useState,
	useContext,
	useCallback,
	useMemo,
	useRef,
	FC,
	SetStateAction,
	Dispatch,
} from "react"
import useDidMountEffect from "../../hooks/useDidMountEffect"
import Card from "../Card"
import InfiniteScroll from "../InfiniteScroll"

import { ModalContext } from "../../contexts/ModalContext"
import { ThemeContext } from "../../contexts/ThemeContext"

import s from "./CardList.module.scss"
import c from "./Sidebar.module.scss"
import Head from "next/head"
import { Hape } from "../../types"
import { fashionIndexApi } from "../../../utils/fashion-index/api"
import { useGetMyHapes } from "../../../services/fashion-index/ProfileServices"
import { HapeDetails } from "../../types/fashionIndex/InventoryTypes"

interface CardListProps {
	hapes: Hape[]
	setHapes: Dispatch<SetStateAction<Hape[]>>
	nftNycHapes: Hape[]
	onOpenModal: (card: HapeDetails) => void
	appliedFilters: any
	setAppliedFilters: Dispatch<SetStateAction<any[]>>
	filterType: any
	isMobile: boolean
	isFilterSidebarOpen: boolean
	setSearchedToken: Dispatch<SetStateAction<string>>
	searchValue: string
	isViewingMyHapes: boolean
	hapeRankings: any[]
}

export const CardList: FC<CardListProps> = ({
	hapes,
	setHapes,
	nftNycHapes,
	onOpenModal,
	appliedFilters,
	setAppliedFilters,
	filterType,
	isMobile,
	isFilterSidebarOpen,
	setSearchedToken,
	searchValue,
	isViewingMyHapes,
	hapeRankings,
}) => {
	const { appliedSortBy, setAppliedSortBy, badReset } = useContext(ModalContext)

	const { theme, setTheme, isThemeModalOpen, setThemeModalOpen } =
		useContext(ThemeContext)

	const [eventHapes, setEventHapes] = useState(nftNycHapes)
	const [hasMore, setHasMore] = useState(true)
	const maxHapes = 8192
	const [totalFilteredHapes, setTotalFilteredHapes] = useState(maxHapes)
	const [loading, setLoading] = useState(false)
	const { APP_URI } = process.env
	useEffect(() => {
		if (hapes.length === maxHapes) {
			setHasMore(false)
		}

		if (hapes.length === totalFilteredHapes) {
			setHasMore(false)
		}
	}, [hapes])

	const handleOpensea = () => {
		window.open("https://opensea.io/collection/hapeprime")
	}

	// GET HAPEs by Filter and Sort
	// useDidMountEffect(async () => {
	// 	setLoading(true)
	// 	const sort = appliedSortBy ? appliedSortBy.value : null

	// 	const params = JSON.stringify({
	// 		appliedFilters: appliedFilters ?? [],
	// 		appliedSortBy: sort ?? {},
	// 		filterType: filterType
	// 	})

	// 	let res = await fetch(`/api/users?filters=${params}`, {
	// 		method: "GET"
	// 	})

	// 	let newHapes = await res.json()
	// 	const filteredCount = newHapes.filteredCount
	// 	newHapes = newHapes.data
	// 	setHapes(newHapes)
	// 	setEventHapes(null)
	// 	setTotalFilteredHapes(filteredCount)
	// 	setHasMore(true)
	// 	setLoading(false)
	// 	window.scrollTo(0, 0)
	// }, [appliedFilters, appliedSortBy])

	useEffect(() => {
		if (badReset > 0) {
			resetFilter()
		}
	}, [badReset])

	const resetFilter = async () => {
		setLoading(true)
		setAppliedFilters([])
		setAppliedSortBy(null)
		const params = JSON.stringify({})

		let newHapes = await fetch(`/api/users?filters=${params}`, {
			method: "GET",
		})
			.then((response) => response.json())
			.then((hapes) => hapes.data)

		const filteredCount = newHapes.filteredCount
		setHapes(newHapes)
		//updateEventHapes()
		setSearchedToken("")
		setTotalFilteredHapes(filteredCount)
		setHasMore(true)
		setLoading(false)
		window.scrollTo(0, 0)
	}

	const updateEventHapes = async (e) => {
		// let newEventHapes = await fetch(`/api/inventory/getNftNycHapes`, {
		// 	method: "GET"
		// })
		// 	.then(response => response.json())
		// 	.then(hapes => hapes.data)
		let newEventHapes = []
		setEventHapes(newEventHapes)
	}

	const [hapeGalleryEntries, setHapeGalleryEntries] = useState([])
	const [currentPage, setCurrentPage] = useState(1)
	const [isLoading, setIsLoading] = useState(false)
	const [hasFetchedInitial, setHasFetchedInitial] = useState(false)

	const fetchEntries = async (page, reset = false) => {
		if (!reset && (isLoading || (page === 1 && hasFetchedInitial))) {
			// Prevent fetching if already loading or if the first page has been fetched once
			return
		}
		setIsLoading(true)
		try {
			const response = await fashionIndexApi.get(
				`get-hape-gallery/?page=${page}`,
			)
			if (reset) {
				setHapeGalleryEntries(response.data.results)
			} else {
				setHapeGalleryEntries((prevEntries) => [
					...prevEntries,
					...response.data.results,
				])
			}

			if (page === 1) {
				setHasFetchedInitial(true) // Mark the initial fetch to prevent duplicates
			}
		} catch (error) {
			console.error("Failed to fetch leaderboard entries:", error)
		} finally {
			setIsLoading(false)
		}
	}

	// GET HAPEs by Token ID Search
	useDidMountEffect(async () => {
		const searchVal = searchValue ? parseInt(searchValue) : null
		if (searchVal >= 1 && searchVal <= 8888) {
			const url = `filter-gallery-by-hape-id/${searchValue}/`
			let filteredHapeGallery = await fashionIndexApi
				.get(url)
				.then((response) => response.data)
			setHapeGalleryEntries(filteredHapeGallery.results)
			setTotalFilteredHapes(1)
			setEventHapes(null)
			setHasMore(false)
			window.scrollTo(0, 0)
		} else {
			setCurrentPage(1)
			fetchEntries(1, true)
		}
	}, [searchValue])

	useEffect(() => {
		fetchEntries(currentPage)
	}, [currentPage])

	const handlePreviousPage = () => {
		setCurrentPage(currentPage > 1 ? currentPage - 1 : 1)
	}

	const handleNextPage = () => {
		if (!isLoading) {
			setCurrentPage(currentPage + 1) // You might want to add a check to not go beyond the last page
		}
	}

	const getMoreHapes = useCallback(async () => {
		if (!isViewingMyHapes) {
			let lastHape = 0
			let startType = ""
			if (appliedSortBy) {
				if (appliedSortBy.value.includes("Rare")) {
					lastHape = hapes.length > 0 ? hapes[hapes.length - 1].rank : 0
					startType = "rank"
				} else if (appliedSortBy.value === "token_id") {
					lastHape = hapes.length > 0 ? hapes[hapes.length - 1].token_id : 0
					startType = "token_id"
				}
			} else {
				// default case. make sure to change in backend too
				lastHape = hapes.length > 0 ? hapes[hapes.length - 1].rank : 0
				startType = "rank"
			}

			const sort = appliedSortBy ? appliedSortBy.value : null
			const listedHapes = hapes.map((h) => h.token_id)
			const params = JSON.stringify({
				startType: startType,
				start: lastHape,
				appliedFilters: appliedFilters,
				appliedSortBy: sort,
				filterType: filterType,
				listedHapes: [...listedHapes],
			})

			let res = await fetch(`/api/users?filters=${params}`, {
				method: "GET",
			})
			let newHapes = await res.json()
			newHapes = newHapes.data
			setHapes((hape) => [...hape, ...newHapes])
		}
	}, [appliedSortBy, hapes, appliedFilters])

	const removeTrait = (trait) => {
		const removedFilters = appliedFilters.filter((f) => f !== trait)
		setAppliedFilters([...removedFilters])
	}

	const [isThemeActivated, setThemeActivated] = useState(false)

	const onThemeHeroAnimationComplete = () => {
		setTimeout(() => {
			setThemeActivated(false)
		}, 1000)
	}

	const [cardSize, setCardSize] = useState(200)

	const { data: myHapesData } = useGetMyHapes()

	// put each hape card in a list to pass to "hapesToDisplay"
	const myHapes = useMemo(() => {
		return myHapesData?.length > 0
			? myHapesData.map((hape) => (
					<Card
						key={hape.token_id}
						card={hape}
						rank={hape.rank}
						onReadMore={onOpenModal}
						cardSize={cardSize}
						isViewingMyHapes={isViewingMyHapes}
						isMobile={isMobile}
					/>
				))
			: []
	}, [myHapesData, onOpenModal, cardSize, isViewingMyHapes, isMobile])

	const galleryHapes = useMemo(() => {
		return hapeGalleryEntries?.length > 0
			? hapeGalleryEntries.map((hape) => (
					<Card
						key={hape.token_id}
						card={hape}
						rank={hape.rank}
						onReadMore={onOpenModal}
						cardSize={cardSize}
						isViewingMyHapes={isViewingMyHapes}
						isMobile={isMobile}
					/>
				))
			: []
	}, [hapeGalleryEntries, onOpenModal, cardSize, isViewingMyHapes, isMobile])

	const hapesToDisplay = useMemo(() => {
		return isViewingMyHapes ? myHapes : galleryHapes
	}, [isViewingMyHapes, myHapes, galleryHapes])

	const infScrollParentRef = useRef(null)
	const infScrollParentRefMobile = useRef(null)

	const infiniteScroll = (
		<InfiniteScroll
			cards={hapesToDisplay}
			parentRef={infScrollParentRef}
			parentRefMobile={infScrollParentRefMobile}
			getMoreHapes={getMoreHapes}
			isMobile={isMobile}
			setCardSize={setCardSize}
			isFilterSidebarOpen={isFilterSidebarOpen}
			isViewingMyHapes={isViewingMyHapes}
			handleNextPage={handleNextPage}
			isLoading={isLoading}
		/>
	)

	return (
		<>
			<Head>
				<link
					rel="preload"
					href="/fonts/Konnect.otf"
					as="font"
					type="font/otf"
					crossOrigin="anonymous"
				/>
			</Head>
			<div id="cards" ref={infScrollParentRefMobile}>
				<div className={c.main_container}>
					<div
						className={
							isFilterSidebarOpen
								? c.infinite_scroll_filter_open
								: c.infinite_scroll_filter_closed
						}
						ref={infScrollParentRef}
					>
						{infiniteScroll}
					</div>
				</div>
			</div>
		</>
	)
}

export default CardList
