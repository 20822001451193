import Head from "next/head"
import s from "../styles/Home.module.scss"
import React, {
	useState,
	useEffect,
	useContext,
	useRef,
	useCallback,
	useMemo,
	FC,
} from "react"
import Header from "../src/components/Header/Header"

import { ModalContext, ModalProvider } from "../src/contexts/ModalContext"

import { motion, AnimatePresence, useAnimation, useCycle } from "framer-motion"
import { ThemeContext } from "../src/contexts/ThemeContext"
import { DarkModeContext } from "../src/contexts/DarkModeContext"
import { useDimensions } from "../src/hooks/useDimensions"
import useDidMountEffect from "../src/hooks/useDidMountEffect"

import { getSelectorsByUserAgent, isIOS } from "react-device-detect"
import Sidebar from "../src/components/Layout/Sidebar/Sidebar"
import Layout from "../src/components/Layout/Layout"
import useWindowDimensions from "../src/hooks/useWindowDimensions"
import { mobileWidth, tabletWidth } from "../utils/enum/constants"
import ModalTheme from "../src/components/ModalTheme"
import { WalletContext } from "../src/contexts/WalletContext"
import ModalCard from "../src/components/ModalCard"
import { useDebounce } from "use-debounce"
import ModalAbout from "../src/components/ModalAbout"
import ModalFilters from "../src/components/ModalFilters"
import CardList from "../src/components/CardList"
import ModalMenu from "../src/components/ModalMenu/ModalMenu"
import { Hape } from "../src/types"
import { useResetModalLayersState } from "../store/global/ModalCardOverlayState"
import {
	useResetInventoryState,
	walletAddressAtom,
} from "../services/fashion-index/inventoryServices"
import { useSetAtom } from "jotai"
import { useGetMyHapes } from "../services/fashion-index/ProfileServices"
import { HapeDetails } from "../src/types/fashionIndex/InventoryTypes"
import { deviceTypeAtom } from "../store/global/DeviceState"

interface FashionIndexPageProps {
	deviceType: "Mobile" | "Tablet" | "Desktop"
}
const Home: FC<FashionIndexPageProps> = ({ deviceType }) => {
	//

	const [admin, setAdmin] = useState<boolean>(
		process.env.NEXT_PUBLIC_IS_FASHION_INDEX_ADMIN_ON === "true",
	)
	const [adminCheck, setAdminCheck] = useState("")
	const [filters, setFilters] = useState<any[]>(null)
	const [filtersCore, setFiltersCore] = useState<any[]>(null)
	const [isMobile, setIsMobile] = useState<boolean>(true)

	const { theme, setTheme } = useContext(ThemeContext)
	const { isDarkModeEnabled, toggleDarkMode } = useContext(DarkModeContext)

	const setDeviceType = useSetAtom(deviceTypeAtom)
	useEffect(() => {
		setDeviceType(deviceType)
	}, [deviceType])

	useEffect(() => {
		const runFilters = async () => {
			// GET filters
			let filtersResult = await fetch(`/api/getfilters`, {
				method: "GET",
			})

			let finalFilters = await filtersResult.json()
			finalFilters = finalFilters.data
			setFilters(finalFilters)

			// GET filters
			let filtersResultCore = await fetch(`/api/getFiltersCore`, {
				method: "GET",
			})

			let finalFiltersCore = await filtersResultCore.json()
			finalFiltersCore = finalFiltersCore.data
			setFiltersCore(finalFiltersCore)

			// let getAssetFile = await fetch(`${API_URI}/api/getAwsAssetFile`, {
			// 	method: "GET"
			// })
		}
		runFilters()
	}, [])

	const [isTablet, setIsTablet] = useState<boolean>(false)
	const windowSize = useWindowDimensions()
	useEffect(() => {
		if (windowSize.width < tabletWidth) {
			setIsMobile(true)
		} else {
			setIsMobile(false)
		}

		if (windowSize.width > mobileWidth && windowSize.width < tabletWidth) {
			setIsTablet(true)
		}
	}, [windowSize])

	const handleAdminCheck = (e) => {
		if (e.target.value === "pringles") {
			setAdmin(false)
		}
	}

	const getBackgroundColor = () => {
		// if (isDarkModeEnabled) {
		// 	return "#1c1c1c"
		// } else {
		// 	return "#ffffff"
		// }
		return "#1c1c1c"
	}

	const controls = useAnimation()

	const handleColorChange = (theme) => {
		controls.start({
			backgroundColor: theme,
			transition: { duration: 0.3 },
		})
		setTheme(theme)
		document.body.style.backgroundColor = theme
	}

	const handleDarkModeToggle = () => {
		if (isDarkModeEnabled) {
			controls.start({
				backgroundColor: "#1c1c1c",
				transition: { duration: 0.6 },
			})
		} else {
			controls.start({
				backgroundColor: "#ffffff",
				transition: { duration: 0.6 },
			})
		}
	}

	useDidMountEffect(() => {
		handleDarkModeToggle()
	}, [isDarkModeEnabled])

	useDidMountEffect(() => {
		handleColorChange(theme)
	}, [theme])

	const containerRef = useRef(null)
	const [enableBodyScroll, setEnableBodyScroll] = useState(true)

	///

	const [entity, setEntity] = useState<HapeDetails>(null)
	const [isModalOpen, setModalIsOpen] = useState<boolean>(false)
	const [isFilterModalOpen, setIsFilterModalOpen] = useState<boolean>(false)
	const [isAboutModalOpen, setAboutModalOpen] = useState<boolean>(false)
	const [isMenuModalOpen, setMenuModalOpen] = useState<boolean>(false)
	const [isFilterSidebarOpen, setIsFilterSidebarOpen] = useState<boolean>(false)
	const { resetModalColors } = useContext(ModalContext)

	const { APP_URI } = process.env
	const { badReset, setBadReset } = useContext(ModalContext)

	const { unSign } = useContext(WalletContext)

	// Modal Card
	type ModalCallbackType = (...args: Array<HapeDetails>) => void
	const openModal = useCallback<ModalCallbackType>(
		(card) => {
			setEntity(card)
			setModalIsOpen(true)
		},
		[setEntity, setModalIsOpen],
	)

	const closeModal = useCallback(() => {
		setEntity(null)
		setModalIsOpen(false)
		unSign()
		resetModalColors()
	}, [setEntity, setModalIsOpen, setIsFilterModalOpen])

	// About Modal
	const openAboutModal = useCallback(
		(isAbout: boolean) => {
			setMenuModalOpen(isAbout)
			setAboutModalOpen(true)
			setEnableBodyScroll(false)
		},
		[setAboutModalOpen],
	)

	const closeAboutModal = useCallback(() => {
		setAboutModalOpen(false)
		setEnableBodyScroll(true)
	}, [setAboutModalOpen])

	const [appliedFilters, setAppliedFilters] = useState<any[]>([])
	const [appliedFiltersCore, setAppliedFiltersCore] = useState<any[]>(null)
	const [filterType, setFilterType] = useState<string>("individual")

	const modalControls = useAnimation()
	const aboutModalControls = useAnimation()

	const neutralizeBack = (callback) => {
		window.history.pushState(null, "", window.location.href)
		window.onpopstate = () => {
			window.history.pushState(null, "", window.location.href)
			callback()
		}
	}

	useEffect(() => {
		if (isModalOpen) {
			neutralizeBack(triggerModalClose)
		}
	}, [isModalOpen])

	const revivalBack = () => {
		window.onpopstate = undefined
		window.history.back()
	}

	const triggerModalOpen = async () => {
		await modalControls.start({
			right: 0,
			opacity: 1,
			transition: { duration: 0.35 },
		})
		revivalBack
		setEnableBodyScroll(false)
	}
	const resetModalLayersState = useResetModalLayersState()
	const resetInventoryState = useResetInventoryState()
	const triggerModalClose = async () => {
		await modalControls.start({
			right: "100%",
			opacity: 0,
			transition: { duration: 0.35 },
		})
		closeModal()
		resetModalLayersState()
		resetInventoryState()
		setEnableBodyScroll(true)
	}

	const triggerAboutModalOpen = async () => {
		await aboutModalControls.start({
			right: 0,
			opacity: 1,
			transition: { duration: 0.35 },
		})
		setEnableBodyScroll(false)
	}

	const triggerAboutModalClose = async () => {
		await aboutModalControls.start({
			right: "100%",
			opacity: 0,
			transition: { duration: 0.35 },
		})
		closeAboutModal()
		setEnableBodyScroll(true)
	}

	const [hapeRankings, setHapeRankings] = useState<any[]>(null)
	useEffect(() => {
		const fetchHapeRankings = async () => {
			const getHapeRankings = await fetch(`/api/getHapeRankings`, {
				method: "GET",
			})
				.then((response) => response.json())
				.then((response) => response.data)
			setHapeRankings(getHapeRankings)
		}
		fetchHapeRankings()
	}, [])

	const [modalCard, setModalCard] = useState<JSX.Element>(null)
	useEffect(() => {
		if (entity) {
			setModalCard(
				<ModalCard
					isMobile={isMobile}
					card={entity}
					setIsFilterSidebarOpen={setIsFilterSidebarOpen}
					modalControls={modalControls}
					triggerOpen={triggerModalOpen}
					triggerClose={triggerModalClose}
					windowSize={windowSize}
					isIOS={isIOS}
					isTablet={isTablet}
				/>,
			)
		} else {
			setModalCard(null)
		}
	}, [entity, isMobile, closeModal, isIOS, isTablet])

	const [modalAbout, setModalAbout] = useState<JSX.Element>(null)
	useEffect(() => {
		if (isAboutModalOpen) {
			setModalAbout(
				<ModalAbout
					aboutModalControls={aboutModalControls}
					triggerOpen={triggerAboutModalOpen}
					triggerClose={triggerAboutModalClose}
					isMobile={isMobile}
					isAbout={isMenuModalOpen}
					isHapeOrNot={false}
					isInstructions={false}
					openAboutModal={openAboutModal}
					isMarketplace={false}
					openCartModal={() => {}}
					openOrderModal={() => {}}
				/>,
			)
		} else {
			setModalAbout(null)
		}
	}, [isAboutModalOpen, closeAboutModal, isMobile, isMenuModalOpen])

	const [searchedToken, setSearchedToken] = useState<string>("")
	const [searchValue] = useDebounce(searchedToken, 500)

	const onChangeSearchByTokenId = (e) => {
		e?.preventDefault()
		setSearchedToken(e.target.value)
	}

	const handleFilterCollapse = () => {
		if (isModalOpen) {
			triggerModalClose()
			setFilterBackgroundColor(hapeColor)
		} else {
			if (isMobile) {
				setEnableBodyScroll(isFilterSidebarOpen)
			}
			setIsFilterSidebarOpen((prevState) => !prevState)
		}
	}

	const sidebarFilter = useMemo(() => {
		return (
			<ModalFilters
				isMobile={isMobile}
				filters={filters}
				filtersCore={filtersCore}
				onClose={handleFilterCollapse}
				isOpen={isFilterModalOpen}
				appliedFilters={appliedFilters}
				appliedFiltersCore={appliedFiltersCore}
				setAppliedFilters={setAppliedFilters}
				setAppliedFiltersCore={setAppliedFiltersCore}
				filterType={filterType}
				setFilterType={setFilterType}
				setSearchedToken={setSearchedToken}
				onChangeSearchByTokenId={onChangeSearchByTokenId}
				searchedToken={searchedToken}
				handleFilterCollapse={handleFilterCollapse}
			/>
		)
	}, [
		isMobile,
		isFilterModalOpen,
		appliedFilters,
		filters,
		handleFilterCollapse,
		filterType,
		searchedToken,
	])

	const [hapes, setHapes] = useState<Hape[]>([])
	const [myHapes, setMyHapes] = useState<Hape[]>(null)
	const { account, connect, disconnect, getFormattedAddress, web3Provider } =
		useContext(WalletContext)
	const setWalletAddress = useSetAtom(walletAddressAtom)
	setWalletAddress(account)

	const [isViewingMyHapes, setIsViewingMyHapes] = useState<boolean>(false)
	const [isViewingMyHapesPassed, setIsViewingMyHapesPassed] =
		useState<boolean>(false)

	const { data: myHapesData, refetch: refetchGetMyHapes } = useGetMyHapes()

	const handleGetMyHapes = async (e) => {
		e.preventDefault()
		if (!isViewingMyHapes) {
			if (!web3Provider) {
				await connect()
			}
			refetchGetMyHapes()
			setIsViewingMyHapes(true)
		} else {
			setIsViewingMyHapes(false)
			setBadReset((prev) => prev + 1)
			setMyHapes(null)
		}
	}

	useEffect(() => {
		const getHapes = async () => {
			// GET hapes
			const params = JSON.stringify({})
			let hapes = await fetch(`/api/users?filters=${params}`, {
				method: "GET",
			})
				.then((response) => response.json())
				.then((response) => response.data)

			setHapes(hapes)
		}
		getHapes()
	}, [])

	useEffect(() => {
		const fetchMyHapes = async () => {
			let myHapes = await fetch(`/api/getMyHapes?walletAddress=${account}`, {
				method: "GET",
			})
				.then((response) => response.json())
				.then((res) => res.data)
			setMyHapes(myHapes)
			return myHapes
		}
		if (account && !isViewingMyHapes) {
			setBadReset((prev) => prev + 1)
			setIsViewingMyHapesPassed(false)
		} else if (account) {
			fetchMyHapes().then((hapes) => {
				setIsViewingMyHapesPassed(true)
				setHapes(hapes)
				window.scrollTo(0, 0)
			})
		} else if (!account) {
			if (badReset > 0) {
				setMyHapes(null)
				setIsViewingMyHapes(false)
				setIsViewingMyHapesPassed(false)
				if (isViewingMyHapes) {
					setBadReset((prev) => prev + 1)
				}
			}
		}
	}, [account, isViewingMyHapes])

	// list of cards for all the hapes on the fashion index page at endpoint '/'
	const cardList = useMemo(() => {
		return (
			<CardList
				isMobile={isMobile}
				hapes={hapes}
				setHapes={setHapes}
				nftNycHapes={[]}
				onOpenModal={openModal}
				appliedFilters={appliedFilters}
				setAppliedFilters={setAppliedFilters}
				filterType={filterType}
				isFilterSidebarOpen={isFilterSidebarOpen}
				setSearchedToken={setSearchedToken}
				searchValue={searchValue}
				isViewingMyHapes={isViewingMyHapesPassed}
				hapeRankings={hapeRankings}
			/>
		)
	}, [
		JSON.stringify(hapes),
		openModal,
		setIsFilterModalOpen,
		appliedFilters,
		setAppliedFilters,
		filterType,
		searchedToken,
		isMobile,
		isFilterSidebarOpen,
		searchValue,
		isViewingMyHapesPassed,
		hapeRankings,
	])

	const { hapeColor, currentHapeTokenId, setCurrentHapeTokenId } =
		useContext(ModalContext)

	const [filterBackgroundColor, setFilterBackgroundColor] = useState<string>(
		!isMobile ? "#262626" : "#202020",
	)

	useEffect(() => {
		setFilterBackgroundColor(!isMobile ? "#262626" : "#202020")
	}, [isMobile])

	useEffect(() => {
		if (isModalOpen) {
			setFilterBackgroundColor(hapeColor)
		} else {
			setFilterBackgroundColor(!isMobile ? "#262626" : "#202020")
			setCurrentHapeTokenId(null)
		}
	}, [isModalOpen, hapeColor])

	const [unlock, setUnlock] = useState(false)

	const { signToEarlyAccess } = useContext(WalletContext)

	useEffect(() => {
		const getEarly = async () => {
			if (!web3Provider) {
				await connect()
			}
		}

		//getEarly()
	}, [account])

	const signToEarly = async () => {
		const isAllowed = await signToEarlyAccess()
		if (isAllowed) {
			setUnlock(true)
		}
	}

	return (
		<div>
			<Header />
			<Head>
				<title>HAPE | Fashion Index</title>
				<link rel="icon" href="/favicon_rebrand.ico" />
				<link
					rel="preload"
					href="/fonts/Konnect-Black.otf"
					as="font"
					crossOrigin=""
					type="font/otf"
				/>
				<link
					rel="preload"
					href="/fonts/Konnect-Bold.otf"
					as="font"
					crossOrigin=""
					type="font/otf"
				/>
				<link
					rel="preload"
					href="/fonts/Konnect-Light.otf"
					as="font"
					crossOrigin=""
					type="font/otf"
				/>
				<link
					rel="preload"
					href="/fonts/Konnect-SemiBold.otf"
					as="font"
					crossOrigin=""
					type="font/otf"
				/>
			</Head>
			<style jsx global>
				{`
					body {
						background-color: ${getBackgroundColor()};
					}

					html,
					body,
					body > div,
					#CustomBody {
						height: 100%; !important
						z-index: -1; !important
					}

					.web3modal-modal-lightbox {
						z-index: 999 !important;
					}

					body {
						position: ${!enableBodyScroll && !isIOS ? "fixed" : "static"} !important;
						width: 100%; !important
					}
				`}
			</style>
			{/* <SideFilter /> */}

			<AnimatePresence>
				<motion.div
					className={s.black_radial_bg}
					animate={controls}
					ref={containerRef}
					id="CustomBody"
				>
					{admin && !unlock ? (
						<div
							style={{
								width: "100%",
								height: "100vh",
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								flexDirection: "column",
							}}
						>
							<div style={{ color: "white", fontFamily: "Konnect" }}>
								Fashionverse is under maintenance.
							</div>
							{!account && (
								<button className={s.early_button} onClick={connect}>
									Connect Wallet
								</button>
							)}
							<span style={{ color: "white", fontFamily: "Konnect" }}>
								{account}
							</span>
							<button className={s.early_button} onClick={signToEarly}>
								Sign to login
							</button>
						</div>
					) : (
						<>
							<div className={s.background}>
								<div>
									<Sidebar
										openAboutModal={openAboutModal}
										isFilterSidebarOpen={isFilterSidebarOpen}
										filterBackgroundColor={filterBackgroundColor}
										sidebarFilter={sidebarFilter}
										isViewingMyHapes={isViewingMyHapes}
										isModalOpen={isModalOpen}
										currentHapeTokenId={currentHapeTokenId}
										handleGetMyHapes={handleGetMyHapes}
										handleFilterCollapse={handleFilterCollapse}
										openLeaderboardModal={null}
										isHapeOrNot={false}
										isMarketplace={false}
										openCartModal={null}
										openInventoryModal={null}
										goToMarketplaceHomepage={() => {}}
									/>
									<div className={s.content_container}>{cardList}</div>
									{modalAbout}
									{modalCard}
									<ModalTheme />
								</div>
							</div>
						</>
					)}
				</motion.div>
			</AnimatePresence>
			{/* <Footer /> */}
		</div>
	)
}

export async function getServerSideProps({ req }) {
	const userAgent = req.headers["user-agent"]
	const { isMobile, isTablet, isBrowser } = getSelectorsByUserAgent(userAgent)

	return {
		props: {
			deviceType: isMobile ? "Mobile" : isTablet ? "Tablet" : "Desktop",
		},
	}
}

export default Home
